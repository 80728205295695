import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { zeroPad } from "../../../core/utils";

const CountDown = ({ endTime }) => {
  const intl = useIntl();

  const labels = {
    Days: intl.formatMessage({
      defaultMessage: "Days",
      description: "Days CountDown Timer",
    }),
    Hours: intl.formatMessage({
      defaultMessage: "Hours",
      description: "Hours CountDown Timer",
    }),
    Minutes: intl.formatMessage({
      defaultMessage: "Minutes",
      description: "Minutes CountDown Timer",
    }),
    Seconds: intl.formatMessage({
      defaultMessage: "Seconds",
      description: "Seconds CountDown Timer",
    }),
  };
  const calculateTimeLeft = () => {
    const unixTimestampWithOffset = new Date(endTime).getTime() / 1000;

    const currentTimeInMillis = new Date().getTime();
    const currentUnixTimestamp = Math.floor(currentTimeInMillis / 1000);
    const difference = unixTimestampWithOffset - currentUnixTimestamp;

    let timeLeft = {};

    if (difference > 0) {
      const milliseconds = difference * 1000;
      const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
      
      timeLeft = {
        Days: days,
        Hours: hours,
        Minutes: minutes,
        Seconds: seconds,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());
  const timerComponents = [];
  useEffect(() => {
    const interval = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  });

  Object.keys(timeLeft).forEach((interval) => {
    if (interval !== "Seconds" && !timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div
        className="flex flex-col justify-center items-center p-0.5 bg-black xs:h-20 xs:w-20 w-16 h-16 rounded-md"
        key={interval}
      >
        <span className="text-3xl text-white xs:text-4xl">
          {zeroPad(timeLeft[interval], 2)}
        </span>
        <span className="text-xs font-semibold text-brand-500">
          {labels?.[interval] ?? interval}
        </span>
      </div>
    );
  });

  return timerComponents;
};

export default CountDown;
