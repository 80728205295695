import {postCall, getCall, patchCall, putCall, deleteCall} from "../../core/softswiss";

export const getAllTournaments = () => getCall(`/api/tournaments`);

export const getTournament = (id) => getCall(`/api/tournaments/${id}`);

export const participateInTournament = (id) => postCall(`/api/tournaments/${id}/confirm`);

export const getStatusInTournament = (id) => getCall(`/api/tournaments/${id}/status`);

export const getPlayerTournamentStatuses = () => getCall('/api/tournaments/statuses');