import { useIntl } from "react-intl";
import Link from "@components/Link";
import { useModal } from "@components/Modal/ModalContext";
import { useDevice } from "@components/Provide/DeviceContext";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import CountDown from "./CountDown";
import Button from "../../Button";
import ImageLayer from "../../Layers/ImageLayer";

const Tournament = ({ item, slug }) => {
  const { showModal } = useModal();
  const { isMobile } = useDevice();
  const intl = useIntl();

  const layersList = {
    ImageLayer,
  };

  const currentDate = new Date();
  const isActive =
    isAfter(currentDate, parseISO(item?.startdate)) &&
    isBefore(currentDate, parseISO(item?.enddate));
  const isValidEndDate = new Date(parseISO(item?.enddate)) > currentDate;
  const isValidStartDate = new Date(parseISO(item?.startdate)) > currentDate;

  return (
    <div className="relative flex flex-col w-full overflow-hidden transition-all transform scale-100 rounded-md border-1 h-110 border-brand-600 group cursor:pointer md:hover:scale-105">
      {item?.imageBlock?.map((layer) => {
        const LayerComponent = layersList[layer.component];
        return (
          <div className="absolute top-0 left-0 object-cover w-full h-full rounded-md">
            <LayerComponent {...layer.settings} />
          </div>
        );
      })}
      <div
        className="flex flex-col justify-end w-full h-full px-4 py-8 sm:p-8"
        style={{ zIndex: "1" }}
      >
        <div dangerouslySetInnerHTML={{ __html: item?.title }} />
        <span className="mt-2 text-sm text-white">{item?.description}</span>
        <span className="mt-4 text-xs font-semibold text-white">
          {/* if tournament is still on going */}
          {isValidEndDate &&
            isActive &&
            intl.formatMessage({
              defaultMessage: "Ends in",
              description: "Ends in Label",
            })}

          {/* if tournament ended */}
          {!isValidEndDate &&
            isActive &&
            intl.formatMessage(
              {
                defaultMessage: "Ended on {date}",
                description: "Ended on Label",
              },
              { date: new Date(item?.enddate?.split("T")[0]).toDateString() }
            )}

          {/* if tournament has not started yet */}
          {isValidStartDate &&
            !isActive &&
            intl.formatMessage({
              defaultMessage: "Starts in",
              description: "Starts in Label",
            })}

          {/* if tournament has started */}
          {!isValidStartDate &&
            !isActive &&
            intl.formatMessage(
              {
                defaultMessage: "Started on {date}",
                description: "Started on Label",
              },
              { date: new Date(item?.startdate?.split("T")[0]).toDateString() }
            )}
        </span>
        <div className="flex flex-col" style={{ width: `fit-content` }}>
          {
            <div className="flex flex-row mt-1 gap-x-2">
              <CountDown
                endTime={
                  isActive
                  ? item?.enddate
                  : item?.startdate
                }
              />
            </div>
          }
          <Link
            trackName="Tournament Detail"
            trackParams={{ component: "Tournament", tournament: item }}
            to="tournamentDetail"
            params={{ slug }}
            className="mt-4 text-lg text-black"
          >
            <Button.Action arrow="right" className="w-full">
              {intl.formatMessage({
                defaultMessage: "Get involved",
                description: "Button label",
              })}
            </Button.Action>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Tournament;
