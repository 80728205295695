import React from "react";
import { useDevice } from "@components/Provide/DeviceContext";
import { useMediaQuery } from "react-responsive";
import cx from "classnames";
import { isBrowser } from "browser-or-node";

const ImageLayer = ({ image, imagemobile, className }) => {
  const { isMobile } = useDevice();

  const minShowWidth = useMediaQuery({
    query: "(min-width: 768px)",
  });

  // useEffect(() => {
  //   setEnv("client");
  // }, []);
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  let img = null;
  if (!isBrowser) {
    img = isMobile.any ? imagemobile?.path : image?.path;
  } else {
    img = minShowWidth && isLandscape ? image?.path : imagemobile?.path;
  }

  if (!img) {
    return null;
  }

  return (
    <img
      src={`https://cockpit-ch.madmenmt.com/${img}`}
      className={cx(
        "z-1 absolute md:absolute  top-0 left-0 right-0 bottom-0 flex items-center w-full object-cover object-center w-full h-full",
        className
      )}
      key={isBrowser ? "browser" : "server"}
    />
  );
};

export default ImageLayer;
